<section>
  <div>
    <img src="assets/img/navbar/Logo/PNG/logo.png" alt="Logo" />
    <span>Web Developer</span>
    <span>Chemnitz Germany</span>
  </div>
  <div style="color: #3DCFB6 ; font-family: 'Fira Code';">&copy; Philipp Lötzsch 2024</div>
  <div>
    <a href="{{ link.link }}" *ngFor="let link of links">{{link.name}}</a>
  </div>
</section>
