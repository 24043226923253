<section>
  <div class="content-am">
    <div>
      <h4>Technologies</h4>
      <div class="content-container">
        <h3>Skill Set</h3>
        <span>
          My journey has involved working on diverse projects, employing a range
          of frontend technologies and concepts. I am open to embracing new
          technologies and methodologies to Continously enhance my skills and
          stay ahead in the ever-evolving landscape of web development.
        </span>
        <div style="font-size: 24px">
          <b>You Need <span style="color: #3dcfb6">another skill?</span></b>
        </div>
        <span>
          Feel free to contact me. I look forward to expanding on my previous
          knowledge.
        </span>
        <a
          href="#contact"
          (mouseenter)="startAnimation(linkContent, 200, -150)"
          (mouseleave)="stopAnimation(linkContent)"
        >
          <span [ngStyle]="{ 'right.px': getMarqueePosition(linkContent) }">{{
            linkContent
          }}</span></a
        >
      </div>
    </div>
  </div>
  <div class="skilltree">
    <div class="skill" *ngFor="let exp of skills; let i = index">
      <img
        (mouseenter)="showPopup(i)"
        (mouseleave)="enablePopup = false"
        src="assets/img/skills/{{ exp.img }}.png"
        alt=""
      />
      <span>{{ exp.skill }}</span>
      <div class="popover" *ngIf="i === skills.length - 1 && enablePopup">
        <span>I have a special interest in learning</span>
        <div>
          <div *ngFor="let interest of interests; let i = index">
            <div>
              <img src="assets/img/skills/{{ interest.img }}.png" alt="" />
            </div>
            <span>{{ interest.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
