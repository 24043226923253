<section>
  <div>
    <h4>Contact me</h4>
    <h3>Lets Work together</h3>
    <p style="color: #3DCFB6; font-family: 'Fira Code'; padding: 20px 0;">Got a problem to solve?</p>
    <span>
      Contact me through this form, I am interested in hearing from you,
      knowing your ideas and contributing to your projects with my work</span>
      <p style="padding-top: 20px;">Need aFrontend developer? <span style="color:#3DCFB6 ;">Let’s talk!</span> </p>
  </div>

  <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
    <div>
      <input
        #name="ngModel"
        id="name"
        name="name"
        type="text"
        placeholder="What's your name?"
        [(ngModel)]="contactData.name"
        required
      />
      @if(!name.valid && (name.touched || contactForm.submitted)){
      <label class="invalid" for="name">Oops! it seems your name is missing </label>
      }
      @else{
      <label for="name">Your name goes here</label>
      }
    </div>
    <div>
      <input
        #email="ngModel"
        id="email"
        name="email"
        type="text"
        placeholder="What's your email?"
        [(ngModel)]="contactData.email"
        required
        pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
      />
      @if(email.hasError('required') && (email.touched || contactForm.submitted)){
        <label class="invalid" for="email">Hoppla! your email is required</label>
      }
      @else if(email.hasError('pattern') && contactForm.submitted){
        <label class="invalid" for="email">Pls use the right syntax (name&#64;email.com)</label>
      }
      @else{ <label for="email">youremail&#64;email.com</label>}
     
    </div>
    <div>
      <textarea
        #message="ngModel"
        id="message"
        name="message"
        placeholder="How can I help you?"
        [(ngModel)]="contactData.message"
        cols="30"
        rows="1"
        maxlength="500"
        required
        minlength="4"
      ></textarea>

      @if(!message.valid && (message.touched || contactForm.submitted)){
      <label class="invalid" for="message">What do you need to develop?</label>
      } @else{
      <label for="message">Hello Philipp, I am interested in...</label>
      }
    </div>

    <div style="flex-direction: unset">
      <img src="assets/img/contatct/checkbox_default.png" alt="" />
      <label for="acceptTerms"
        >I've read the <a href="#">privacy policy</a> and agree to the
        processing of my data as outlined.</label
      >
    </div>

    <input type="submit" value="Say Hello :)" />
  </form>
</section>
