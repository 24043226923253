<section>
    <nav class="nav-desktop">
        <div class="language">
            <button [ngClass]="{'chosen': !language}" (click)="english()">EN</button>
            <button [ngClass]="{'chosen': language}" (click)="german()">DE</button>
        </div>
        <a href="#{{ navlink.link }}" *ngFor="let navlink of navLinks">{{ navlink.title }}</a>
    </nav>
    <a href="#" class="logo">
        <img src="assets/img/navbar/Logo/PNG/logo.png" alt="Logo">
    </a>
</section>
