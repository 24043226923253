<section (click)="this.addDialogClosed.emit(false)">
  <article (click)="$event.stopPropagation()">
    <div class="project-content">
      <h6>0{{ currentIndex + 1 }}</h6>
      <span class="title">{{ projects[currentIndex].title }}</span>
      <span class="headline">What is this project about?</span>
      <span>{{ projects[currentIndex].description }}</span>
      <div style="display: flex; gap:16px;">
        <div class="used-skills" *ngFor="let item of projects[currentIndex].codeLanguage">
          <img
            src="assets/img/portfolio/{{ item | lowercase }}_green.png"
            alt="languange"
          />
          <span>{{ item }}</span>
        </div>
      </div>

      <div class="project-links">
        <a href=""
          >Github
          <img src="assets/img/portfolio/arrow_outward_green.png" alt=""
        /></a>
        <a href=""
          >Live Test
          <img src="assets/img/portfolio/arrow_outward_green.png" alt=""
        /></a>
      </div>
    </div>
    <div class="project-image">
      <button (click)="this.addDialogClosed.emit(false)">
        <img src="assets/img/portfolio/close_small.png" alt="" />
      </button>
      <img src="assets/img/portfolio/{{formatTitle(projects[currentIndex].title)}}.png" alt="" />
      <button (click)="nextProject()">
        Next project
        <img src="assets/img/portfolio/arrow_forward_green.png" alt="" />
      </button>
    </div>
  </article>
</section>
